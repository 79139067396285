import de from '~/locales/de.json'
import en from '~/locales/en.json'
import fr from '~/locales/fr.json'

export default {
  fallbackLocale: 'en',
  legacy: false,
  locale: 'en',
  messages: { en, fr, de },
}
